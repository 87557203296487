export const environment = {
    production: false,
    errMsg: 'Failed to complete. Please try again.',
    partner: false,
    config: {
        "haystackUrl": "https://silo.qa.service.75f.io/",
        "auth": {
            "caretakerUrl": "https://caretaker.qa.service.75f.io/api/v1"
        },
        "siteSequencerUrl":"https://seq-runner.qa.service.75f.io",
        "notesUrl": "https://notes-api.qa.service.75f.io/",
        "edgeRunnerApi": "https://micropython-edge-api.azurewebsites.net/",
        'devCycleClientId':"dvc_client_6a871843_2d50_4cd3_8085_838c6ef552ea_54fce3b",
        "filestorageUrl": "https://filestorage.qa.service.75f.io/",
    }
};
